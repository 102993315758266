import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Amplify, Auth } from "aws-amplify";
import Layout from "components/layout";
import Theme from "components/template/Theme";
import appConfig from "configs/app.config";
import ChakraTheme from "configs/theme.chakra";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import history from "./history";
import "./locales";
import mockServer from "./mock";
import store, { persistor } from "./store";

import "@aws-amplify/ui-react/styles.css";

const environment = process.env.NODE_ENV;

if (appConfig.enableMock) {
  mockServer({ environment });
}
Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    region: process.env.REACT_APP_API_REGION,
  },
  API: {
    endpoints: [
      {
        name: "serverless-pdf-chat",
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        region: process.env.REACT_APP_API_REGION,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
});

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter history={history}>
            <Theme>
              <ChakraTheme>
                <Layout />

                <ReactQueryDevtools initialIsOpen={false} />
              </ChakraTheme>
            </Theme>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
