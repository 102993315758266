import { useQuery } from "@tanstack/react-query";
import ApiService from "./ApiService";

export async function apiGetTenant(params) {
  return ApiService.fetchData({
    url: "/tenant/",
    method: "get",
    params,
  });
}

export const useQueryApiGetTenant = (params) => {
  return {
    isLoading: false,
    data: {
      _id: "655611a6a257042e9d196ddd",
      name: "Auramind",
      slug: "auramind",
      active: true,
      details: {
        website: "https://ambev.imatech.io",
        social_media: {
          facebook: "https://www.facebook.com/bernhoeft",
          instagram: "https://www.instagram.com/bernhoeft",
        },
        images: {
          logo_url:
            "https://imalearningplace.s3.us-east-2.amazonaws.com/public_assets/logo-ambev.png",
          banner_url:
            "https://imalearningplace.s3.us-east-2.amazonaws.com/public_assets/banner-ambev.png",
          thumb_url:
            "https://imalearningplace.s3.us-east-2.amazonaws.com/public_assets/banner-ambev-full.jpeg",
        },
        colors: {
          primary_color: "blue",
          secondary_color: "yellow",
        },
      },
      total_lessons: 3,
      total_assessments: 1,
    },
  };
};
useQueryApiGetTenant.queryKey = "apiGetTenant";
